import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('./assets/swi.jpg'),
    drawer: null,
    // Snack bar
    SnackShow: false,
    SnackText: '',
    //Room rent booking
    RoomEvents:[]


  },
  getters: {
    SnackShow: state => state.SnackShow,
    SnackText: state => state.SnackText,
    RoomEvents: state => state.RoomEvents,
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    //Snack
    SnackShow(state, message) {
      state.SnackText = message;

      state.SnackShow = true;
      setTimeout(() => {
        state.SnackShow = false;
      }, 4000)
    },
    //Room rent booking
    StoreEvents(state, payload){
      state.RoomEvents = payload
    }
  },
  actions: {
    // Activate Snack component
    'ActivateSnack'({ commit }, message = '') {
      if (!message) return
      commit('SnackShow', message);
    },
  },
})
