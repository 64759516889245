import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

const theme = {
  primary: '#4CAF50',
  secondary: '#9C27b0',
  accent: '#9C27b0',
  info: '#00CAE3',
  achtdeepgreen: '#378038',
  achtgreen: '#92d050',
  rabatBg: '#d9d9d9',
  rabatTitle: '#474747',
  mainBg: "#f6f9fc",
  fontInActive:"#c3c3c3",
  fontActive:"#3c3c3c",
  titleColor:"#373f50",
  userNavColor:"#fe696a",
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
