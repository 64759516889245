<template>
  <div><router-view/><snack-bar-view /></div>
</template>

<script>
export default {
  name: "App",
  components: {
    SnackBarView: () => import("./views/components/Kit/Snack"),
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

.textFont {
  font-family: "Rubik";
  font-size: 1rem;
}
.titleFont {
  font-family: "Rubik";
  font-size: 1.8rem;
  font-weight: 700;
}
.fontFamlily {
  font-family: "Rubik" !important;
}
</style>