import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: () => import("@/views/Page/Login/LoginView"),
    },
    {
      path: "/main",
      component: () => import("@/views/Index"),
      children: [
        // Dashboard
        {
          name: "Dashboard",
          path: "/Dashboard",
          component: () => import("@/views/Page/Dashboard/Dashboard"),
        },
        {
          name: "Teacher Vacation",
          path: "/VacationDashboard",
          component: () =>
            import("@/views/Page/Dashboard/TeacherVacationDashboard"),
        },
        //seeting page
        {
          name: "Update Room",
          path: "/update/room",
          component: () => import("@/views/Page/SettingRoom/RoomPage"),
        },
        {
          name: "Room",
          path: "/update/room/:id",
          component: () => import("@/views/Page/SettingRoom/SectionsView"),
        },
        {
          name: "Rent Room",
          path: "/rentroom",
          component: () => import("@/views/Page/Room/Rent/RentView"),
        },
        {
          name: "Refund",
          path: "/rentrefund",
          component: () => import("@/views/Page/Room/Refund/RefundView"),
        },
        {
          name: "Cancel Room",
          path: "/cancelroom",
          component: () => import("@/views/Page/Room/Cancel/CancelRoomView"),
        },
        {
          name: "Edit Room",
          path: "/editroom",
          component: () => import("@/views/Page/Room/Edit/EditView"),
        },
        {
          name: "Invoice Management",
          path: "/invoiceroom",
          component: () =>
            import("@/views/Page/Room/InvoiceCenter/ContractView"),
        },
        {
          name: "Contract Management",
          path: "/contractroom",
          component: () =>
            import("@/views/Page/Room/ContractCenter/ContractView"),
        },
        //checklist
        {
          name: "Check List",
          path: "/checklist/management",
          component: () => import("@/views/Page/CheckList/ManagementCenter"),
        },
        {
          name: "Create Checklist",
          path: "/checklist/create",
          component: () => import("@/views/Page/CheckList/CreateCheckList"),
        },
        {
          name: "View/Edit Checklist",
          path: "/checklist/edit",
          component: () => import("@/views/Page/CheckList/CreateCheckList"),
          props: true,
        },
        {
          name: "Upload Checklist",
          path: "/checklist/upload",
          component: () => import("@/views/Page/CheckList/UploadChecklist"),
        },
        {
          name: "老师信息更新",
          path: "/teacher/table",
          component: () =>
            import("@/views/Page/Teacher/TeacherTable/TeacherTable"),
        },

        {
          name: "老师对应课程",
          path: "/teacher/mapcourse",
          component: () =>
            import("@/views/Page/Teacher/TeacherMapCourse/TeacherMapCourse"),
        },
        {
          name: "老师工作计划",
          path: "/teacher/workplan",
          component: () => import("@/views/Page/Teacher/WorkPlan/WorkPlan"),
        },
        {
          name: "老师假期",
          path: "/teacher/vacation",
          component: () =>
            import("@/views/Page/Teacher/TeacherVacation/TeacherVacation"),
        },
        {
          name: "课程大类",
          path: "/course/main",
          component: () =>
            import("@/views/Page/CourseCenter/MainCourse/MainCourse"),
        },
        {
          name: "课程注册",
          path: "/course/register",
          component: () =>
            import("@/views/Page/CourseCenter/RegisterCourse/RegisterCourse"),
        },
        {
          name: "课程修改删除",
          path: "/course/update",
          component: () =>
            import("@/views/Page/CourseCenter/CourseUpdate/CourseUpdate"),
        },
        {
          name: "用户信息更新",
          path: "/user/table",
          component: () => import("@/views/Page/User/UserTable/UserTable"),
        },
        {
          name: "前台奖状",
          path: "/event",
          component: () => import("@/views/Page/Event/Event"),
        },
      ],
    },
  ],
});
export default router;
