// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/base";
// import './plugins/chartist'
// import './plugins/vee-validate'
import vuetify from "./plugins/vuetify";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import axios from "axios";
import i18n from "./i18n";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
// axios.defaults.baseURL = 'http://127.0.0.1:3008/'
axios.defaults.baseURL = "https://backend.acht-toene.com/";
axios.interceptors.request.use((config) => {
  config.headers.Authorization = window.sessionStorage.getItem("token");
  return config;
});
axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    console.log(err);
    if (
      err.response.data == "UnauthorizedError" ||
      err.response.status == 403
    ) {
      router.push("/");
      return Promise.reject(err);
    }
    return Promise.reject(err);
  }
);
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "md",
});
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
